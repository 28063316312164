import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { DataUtil } from '@celum/core';
import { CelumPipesModule } from '@celum/ng2base';
import { CelumChipComponent, CelumChipSetComponent, FilterChip } from '@celum/shared/ui';

import { createEmptyLibrariesFilter, librariesFilterItemByValue, LibrariesFilters } from '../libraries-filter/libraries-filter.item';

@Component({
  selector: 'slib-libraries-filter-chips',
  templateUrl: './libraries-filter-chips.component.html',
  styleUrl: 'libraries-filter-chips.component.scss',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, CelumChipSetComponent, CelumChipComponent, CelumPipesModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LibrariesFilterChipsComponent implements OnChanges {
  @HostBinding('class.celum-filter-chips') protected readonly hostClass = true;

  @Input() public filter: LibrariesFilters;

  @Output() public readonly filterChanged = new EventEmitter<LibrariesFilters>();

  protected chips = signal<FilterChip[]>([]);

  constructor(private translateService: TranslateService) {}

  public ngOnChanges({ filter }: SimpleChanges): void {
    this.chips.set(this.filterToChips(filter.currentValue));
  }

  protected trackFn(index: number): number {
    return index;
  }

  protected filterToChips(filters: LibrariesFilters): FilterChip[] {
    const chips = [];

    if (!DataUtil.isEmpty(filters.name)) {
      chips.push({
        value: `"${filters.name}"`,
        title: '',
        onRemove: () =>
          this.filterChanged.emit({
            ...filters,
            name: ''
          })
      });
    }

    if (!DataUtil.isEmpty(filters.statusIn)) {
      filters.statusIn.forEach(value => {
        const filterItem = librariesFilterItemByValue[value];

        let displayValue = this.translateService.instant(`LIBRARIES.STATUS.${filterItem.value.toUpperCase()}`);
        displayValue = `${displayValue.charAt(0).toUpperCase()}${displayValue.slice(1)}`;

        chips.push({
          title: filterItem.typeTitle,
          value: displayValue,
          onRemove: () => {
            // Special case: Normally we would just filter out the current value, but in order to show the link to filter for your own created libraries correctly,
            // we have to make sure that statusIn is not set if we removed the last value
            const finalStatus = filters.statusIn.filter(existingStatus => existingStatus !== value);
            const finalFilters = { ...filters };
            delete finalFilters.statusIn;

            this.filterChanged.emit({
              ...finalFilters,
              ...(!DataUtil.isEmpty(finalStatus) && { statusIn: finalStatus })
            });
          }
        });
      });
    }

    if (!DataUtil.isEmpty(filters.createdBy)) {
      // Currently the filter will only contain the current user
      chips.push({
        title: '',
        value: `LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.CREATED_BY.CHIP`,
        onRemove: () => {
          // Make sure to remove the property from the filter, otherwise the display of the link to filter for own libraries won't work correctly
          const finalFilters = { ...filters };
          delete finalFilters.createdBy;

          this.filterChanged.emit(finalFilters);
        }
      });
    }

    if (filters.hasSyncError) {
      chips.push({
        title: '',
        value: 'LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.SYNC_ERROR.CHIP',
        onRemove: () => {
          const finalFilter = { ...filters };
          delete finalFilter.hasSyncError;
          this.filterChanged.emit(finalFilter);
        }
      });
    }

    return chips;
  }

  protected clearAllFilters(): void {
    this.filterChanged.emit(createEmptyLibrariesFilter(this.filter.sort));
  }
}
