import { SortDirection } from '@celum/common-components';
import { DataUtil } from '@celum/core';
import { LibraryFindOptions } from '@celum/libraries/shared';

import { LibrariesFilters } from '../components/libraries-filter/libraries-filter.item';

export class LibraryUtils {
  public static convertLibrariesFilterToLibraryFindOptions(filters: LibrariesFilters, offset: number, batchSize: number): LibraryFindOptions {
    if (!filters) {
      return undefined;
    }

    const filter = { ...filters };
    const finalFilter = {
      ...(filter.name && { nameContains: filter.name }),
      ...(!DataUtil.isEmpty(filter.createdBy) && { createdBy: filter.createdBy.oid }),
      ...(!DataUtil.isEmpty(filter.statusIn) && { statusIn: filter.statusIn }),
      ...(filter.hasSyncError === true && { hasSyncError: true })
    };

    const finalSort =
      filter.sort.active === 'relevance'
        ? [`status,${filter.sort.direction}`, `modifiedAt,${filter.sort.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC}`]
        : [`${filter.sort.active},${filter.sort.direction}`];

    return {
      size: batchSize,
      page: batchSize === 0 ? 0 : offset / batchSize,
      sort: finalSort,
      ...finalFilter
    };
  }
}
