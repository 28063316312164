import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

import { DataUtil } from '@celum/core';

import { createEmptyLibrariesFilter, LibrariesFilters } from './libraries-filter.item';

export type LibrariesFilterState = {
  filter: LibrariesFilters;
};

export type LibrariesFilterViewModel = {
  noFiltersApplied: boolean;
  accountId?: string;
} & LibrariesFilterState;

@Injectable()
export class LibrariesFilterService extends ComponentStore<LibrariesFilterState> {
  public vm$ = this.select(state => this.createViewModel(state));

  constructor() {
    super({ filter: createEmptyLibrariesFilter() });
  }

  private createViewModel(state: LibrariesFilterState): LibrariesFilterViewModel {
    return {
      ...state,
      noFiltersApplied: this.noFilterApplied(state.filter)
    };
  }

  public setFilter(filter: LibrariesFilters) {
    this.patchState({ filter });
  }

  public get currentFilter(): LibrariesFilters {
    return this.get().filter;
  }

  private noFilterApplied(filter: LibrariesFilters): boolean {
    return DataUtil.isEmpty(filter.name) && DataUtil.isEmpty(filter.statusIn) && DataUtil.isEmpty(filter.createdBy) && DataUtil.isEmpty(filter.hasSyncError);
  }
}
