import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule as MatCheckBoxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { map } from 'rxjs';

import { UserDetails } from '@celum/authentication';
import { CelumButtonModule, CelumIconModule, CelumLookupAreaModule, ColorConstants, IconConfiguration, LookupArea } from '@celum/common-components';
import { LibraryStatus } from '@celum/libraries/domain';
import { CelumPipesModule, TranslationHelper } from '@celum/ng2base';
import { ClickablePopupDialogComponent } from '@celum/shared/ui';

import { createEmptyLibrariesFilter, LibrariesFilterItem, librariesFilterItemByValue, LibrariesFilters } from './libraries-filter.item';
import { LibrariesFilterService } from './libraries-filter.service';

@Component({
  selector: 'slib-libraries-filter',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatCheckBoxModule,
    MatDatepickerModule,
    MatIconModule,
    MatMenuModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggle,
    MatTooltipModule,

    CelumButtonModule,
    CelumIconModule,
    CelumLookupAreaModule,
    CelumPipesModule,

    ClickablePopupDialogComponent
  ],
  templateUrl: './libraries-filter.component.html',
  styleUrl: './libraries-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [LibrariesFilterService]
})
export class LibrariesFilterComponent {
  @HostBinding('class.sacc-libraries-filter') protected readonly hostClass = true;

  @Output()
  public readonly filterChanged = new EventEmitter<LibrariesFilters>();

  @Input({ required: true })
  public user: UserDetails;

  @ViewChild('firstSearchInput', { static: false, read: LookupArea }) protected searchInput: LookupArea;

  protected readonly filterSections: Map<string, LibrariesFilterItem[]> = new Map([
    [
      'LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.STATUS.TITLE',
      [librariesFilterItemByValue[LibraryStatus.ACTIVE], librariesFilterItemByValue[LibraryStatus.INACTIVE]]
    ]
  ]);

  /**
   * Texts for the sync-error toggle are too long in German and French. Since it's a material component we can't apply the spaceawareTooltip directive to the
   * label, so we have to manually trigger showing the tooltip for those languages...
   */
  protected showSyncErrorTruncationTooltip$ = this.translateHelper.currentLocaleStream().pipe(map(language => language === 'de' || language === 'fr'));

  protected readonly icons = {
    filter: IconConfiguration.small('filter-l').withColor(ColorConstants.SYSTEM_BLACK),
    search: IconConfiguration.medium('search-m').withColor(ColorConstants.BLUE_GRAY_900),
    clear: IconConfiguration.medium('cancel-m').withColor(ColorConstants.BLUE_GRAY_900)
  };

  constructor(
    protected service: LibrariesFilterService,
    private translateHelper: TranslationHelper
  ) {}

  @Input() public set filter(librariesFilter: LibrariesFilters) {
    this.service.setFilter(librariesFilter);
  }

  protected clearAllFilters(): void {
    // Clear only the filters, leave sorting as it is!
    this.filterChanged.emit(createEmptyLibrariesFilter(this.service.currentFilter.sort));
  }

  protected onMenuOpenedChanged(opened: boolean): void {
    if (opened) {
      this.searchInput.focus();
    }
  }

  protected memberNameSearchChanged(name: string): void {
    if (this.service.currentFilter.name !== name) {
      this.filterChanged.emit({ ...this.service.currentFilter, name });
    }
  }

  protected hasCreatedByChanged(): void {
    const currentFilter = { ...this.service.currentFilter };
    if (currentFilter.createdBy) {
      delete currentFilter.createdBy;
    } else {
      currentFilter.createdBy = { oid: this.user.oid, firstName: this.user.firstName, lastName: this.user.lastName };
    }
    this.filterChanged.emit(currentFilter);
  }

  protected hasSyncErrorChanged(): void {
    const currentFilter = { ...this.service.currentFilter };
    if (currentFilter.hasSyncError) {
      delete currentFilter.hasSyncError;
    } else {
      currentFilter.hasSyncError = true;
    }
    this.filterChanged.emit(currentFilter);
  }

  protected isFilterApplied(filter: LibrariesFilterItem, filters: LibrariesFilters): boolean {
    return filters.statusIn?.includes(filter.value);
  }

  public toggleFilter(value: LibraryStatus): void {
    const previous = this.service.currentFilter;
    const previousValue = previous.statusIn;

    let nextValue = [value];
    if (previousValue) {
      nextValue = previousValue.includes(value) ? previousValue.filter(a => a !== value) : [...previousValue, value];
    }

    // Make sure the property gets removed if no values are selected, otherwise the "created by me"-link will not be shown
    if (nextValue.length === 0) {
      delete previous.statusIn;
    }

    this.filterChanged.emit({ ...previous, ...(nextValue.length > 0 && { statusIn: nextValue }) });
  }

  protected onKeyDown(keyboardEvent: KeyboardEvent) {
    if (keyboardEvent.key === 'Tab' || (keyboardEvent.key === 'Tab' && keyboardEvent.shiftKey)) {
      keyboardEvent.stopPropagation();
    }
  }
}
