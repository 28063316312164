<ng-container *ngIf="service.vm$ | async as vm">
  <div class="dashboard_top"></div>
  <div class="dashboard_new-library">
    <button class="dashboard_new-button" data-test-dashboard-new-library mat-button (click)="addNew()">
      <celum-icon class="dashboard_new-button_icon" [configuration]="createIcon"></celum-icon>
      <span class="dashboard_new-button_text">{{ 'COMMON.NEW' | translate | uppercase }}</span>
    </button>

    <celum-table-header class="dashboard_table-header">
      <celum-table-count
        ngProjectAs="table-header-counter"
        [count]="vm.totalNumberOfLibraries"
        [countLabelPlural]="
          vm.totalNumberOfLibraries > 0
            ? showLink()
              ? 'LIBRARIES.DASHBOARD.COUNTER.LIBRARIES_UNFILTERED'
              : 'LIBRARIES.DASHBOARD.COUNTER.LIBRARIES_FILTERED'
            : 'LIBRARIES.DASHBOARD.COUNTER.LIBRARIES_UNFILTERED_ZERO'
        "
        [countLabelSingular]="showLink() ? 'LIBRARIES.DASHBOARD.COUNTER.LIBRARY_UNFILTERED' : 'LIBRARIES.DASHBOARD.COUNTER.LIBRARY_FILTERED'"
        [linkCount]="vm.totalNumberOfOwnLibraries"
        [linkLabelPlural]="'LIBRARIES.DASHBOARD.COUNTER.OWN_LIBRARIES_FILTER_LINK'"
        [linkLabelSingular]="'LIBRARIES.DASHBOARD.COUNTER.OWN_LIBRARY_FILTER_LINK'"
        [showLink]="showLink()"
        (linkClicked)="onCounterLinkClicked()"></celum-table-count>
      <slib-libraries-sorter ngProjectAs="table-header-sorting" [filter]="vm.filter" (sortChanged)="onSortChanged($event)"></slib-libraries-sorter>
      <slib-libraries-filter-chips
        ngProjectAs="table-header-filter-chips"
        [filter]="vm.filter"
        (filterChanged)="service.filterChanged($event)"></slib-libraries-filter-chips>
      <slib-libraries-filter
        ngProjectAs="table-header-filter"
        [filter]="vm.filter"
        [user]="vm.currentUser"
        (filterChanged)="service.filterChanged($event)"></slib-libraries-filter>
    </celum-table-header>
  </div>

  <div class="dashboard_banners">
    <libs-storage-limit-message-box></libs-storage-limit-message-box>
  </div>

  <libs-library-list class="dashboard_library-list celum-scrollbar" [filter]="vm.filter"></libs-library-list>
</ng-container>
