import { Sort } from '@angular/material/sort';

import { AccountAccessStatus, UserDetails } from '@celum/authentication';
import { SortDirection } from '@celum/common-components';
import { LibraryStatus } from '@celum/libraries/domain';
import { SortablePaginationOption } from '@celum/shared/domain';

export enum LibrariesFilterCategory {
  Status
}

export type LibrariesFilterItem = { title: string; typeTitle: string } & { type: LibrariesFilterCategory.Status; value: LibraryStatus };

export const librariesFilterItemByValue: Record<LibraryStatus, LibrariesFilterItem> = {
  [AccountAccessStatus.ACTIVE]: {
    title: 'LIBRARIES.STATUS.ACTIVE',
    typeTitle: 'LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.STATUS.TITLE',
    type: LibrariesFilterCategory.Status,
    value: LibraryStatus.ACTIVE
  },
  [AccountAccessStatus.INACTIVE]: {
    title: 'LIBRARIES.STATUS.INACTIVE',
    typeTitle: 'LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.STATUS.TITLE',
    type: LibrariesFilterCategory.Status,
    value: LibraryStatus.INACTIVE
  }
};

export interface LibrariesFilters extends SortablePaginationOption {
  name?: string;
  statusIn?: LibraryStatus[];
  createdBy?: Pick<UserDetails, 'oid' | 'firstName' | 'lastName'>;
  hasSyncError?: boolean;
}

export function createEmptyLibrariesFilter(sort?: Sort): LibrariesFilters {
  return {
    name: '',
    sort: sort || createDefaultLibrariesFilterSort()
  };
}

export function createDefaultLibrariesFilterSort(): Sort {
  return { active: 'relevance', direction: SortDirection.ASC };
}
