import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CelumEmptyPageModule, EmptyPage, EmptyPageConfig } from '@celum/common-components';
import { CelumListModule } from '@celum/internal-components';
import { AssetMetadataField, LibraryError } from '@celum/libraries/domain';

import { MetadataListRowComponent } from '../metadata-list-row/metadata-list-row.component';

@Component({
  imports: [CommonModule, TranslateModule, CelumEmptyPageModule, CelumListModule, MetadataListRowComponent],
  selector: 'libs-metadata-list',
  templateUrl: './metadata-list.component.html',
  styleUrls: ['./metadata-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetadataListComponent implements OnChanges {
  @Input() public metadata: AssetMetadataField[];

  @Input() public error: LibraryError;

  @Output() public readonly removeItem = new EventEmitter<AssetMetadataField>();

  protected readonly emptyPageConfig: EmptyPageConfig = EmptyPage.noActionConfig(
    'no-metadata-applied',
    'no-metadata-applied',
    'LIBRARIES.CREATE.METADATA.NO_METADATA_SELECTED',
    'normal',
    232
  );

  public ngOnChanges({ metadata }: SimpleChanges): void {
    if (metadata?.currentValue) {
      this.metadata = [...this.metadata].sort((a, b) => a.name.localeCompare(b.name));
    }
  }
}
