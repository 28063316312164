import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { distinctUntilChanged, map, switchMap } from 'rxjs';

import {
  CelumBannerModule,
  CelumEmptyPageModule,
  CelumIconModule,
  CelumMessageModule,
  CelumSnackbarModule,
  ICON_TEXT_ALIGN,
  IconConfiguration
} from '@celum/common-components';
import { LibraryStatus } from '@celum/libraries/domain';
import { LibraryWizardApi } from '@celum/libraries/features/designer';
import { LibraryFindOptions, LibraryStorageService } from '@celum/libraries/shared';
import { StorageLimitMessageBoxComponent } from '@celum/libraries/ui';
import { CelumTableCountComponent, CelumTableHeaderComponent } from '@celum/shared/ui';

import { LibrariesFilterComponent } from './components/libraries-filter/libraries-filter.component';
import { createEmptyLibrariesFilter, LibrariesFilters } from './components/libraries-filter/libraries-filter.item';
import { LibrariesFilterChipsComponent } from './components/libraries-filter-chips/libraries-filter-chips.component';
import { LibrariesSorterComponent } from './components/libraries-sorter/libraries-sorter.component';
import { LibraryListComponent } from './components/library-list/library-list.component';
import { LibraryListService } from './components/library-list/library-list.service';
import { DashboardService } from './dashboard.service';

export const LOCAL_STORAGE_LIBRARIES_FILTER_KEY = 'LIBRARIES_FILTER';

export interface LibraryFilter {
  titleKey: string;
  key: keyof LibraryFindOptions;
  value: string;
  displayValue?: string;
}

@Component({
  imports: [
    CommonModule,

    MatButtonModule,
    MatDialogModule,

    CelumBannerModule,
    CelumEmptyPageModule,
    CelumIconModule,
    CelumMessageModule,
    CelumSnackbarModule,
    CelumTableCountComponent,
    CelumTableHeaderComponent,

    LibrariesFilterChipsComponent,
    LibrariesFilterComponent,
    LibrariesSorterComponent,
    LibraryListComponent,
    StorageLimitMessageBoxComponent
  ],
  providers: [LibraryStorageService, LibraryListService],
  selector: 'libs-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent {
  protected LibraryStatus = LibraryStatus;

  protected readonly createIcon = IconConfiguration.medium('plus-m').withTextAlign(ICON_TEXT_ALIGN.right);

  protected showLink = signal(true);

  constructor(
    protected service: DashboardService,
    private libraryWizardApi: LibraryWizardApi,
    private libraryListService: LibraryListService
  ) {
    service.init();

    // Show the link to filter for your own libraries if the filter is empty
    this.service.vm$
      .pipe(
        map(vm => ({ filter: vm.filter, hasLibraries: vm.totalNumberOfLibraries > 0 })),
        distinctUntilChanged(),
        takeUntilDestroyed()
      )
      .subscribe(({ filter, hasLibraries }) => {
        const emptyFilter = createEmptyLibrariesFilter();
        const isEmptyFilter = JSON.stringify(filter) === JSON.stringify(emptyFilter);
        if (isEmptyFilter && !this.showLink() && hasLibraries) {
          this.showLink.set(true);
        } else if (!hasLibraries || (!isEmptyFilter && this.showLink())) {
          this.showLink.set(false);
        }
      });
  }

  public addNew(): void {
    this.libraryWizardApi
      .open()
      .pipe(switchMap(dialog => dialog.afterClosed()))
      .subscribe(result => {
        if (result) {
          this.service.init();
          this.libraryListService.load();
        }
      });
  }

  protected onCounterLinkClicked(): void {
    this.showLink.set(!this.showLink());
    const currentUser = this.service.currentUser;
    this.service.filterChanged({
      ...this.service.currentFilter,
      createdBy: !this.showLink() ? { oid: currentUser.oid, firstName: currentUser.firstName, lastName: currentUser.lastName } : undefined
    });
  }

  protected onSortChanged(filters: LibrariesFilters): void {
    this.service.filterChanged({ ...this.service.currentFilter, sort: { ...filters.sort } });
  }
}
