import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, input, Output } from '@angular/core';

import { CelumSorterModule, SortDirection, SortField } from '@celum/common-components';
import { SortablePaginationOption } from '@celum/shared/domain';

export interface LibrariesSortField extends SortField {
  field?: `name` | 'createdAt' | `modifiedAt` | `relevance`;
}

@Component({
  selector: 'slib-libraries-sorter',
  imports: [CommonModule, CelumSorterModule],
  templateUrl: './libraries-sorter.component.html',
  styleUrl: './libraries-sorter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibrariesSorterComponent<T extends SortablePaginationOption> {
  @Output() public readonly sortChanged = new EventEmitter<T>();

  public filter = input<T>();

  protected sortDirection = computed(() => (this.filter().sort?.direction === 'asc' ? SortDirection.ASC : SortDirection.DESC));

  protected readonly fieldMap: Record<string, SortField> = {
    relevance: {
      id: 'relevance',
      label: 'LIBRARIES.DASHBOARD.SORT.RELEVANCE'
    },
    createdAt: {
      id: 'createdAt',
      label: 'LIBRARIES.DASHBOARD.SORT.CREATION_DATE'
    },
    name: {
      id: 'name',
      label: 'LIBRARIES.DASHBOARD.SORT.NAME'
    },
    modifiedAt: {
      id: 'modifiedAt',
      label: 'LIBRARIES.DASHBOARD.SORT.MODIFICATION_DATE'
    }
  };

  protected readonly fields: LibrariesSortField[] = Object.values(this.fieldMap);

  protected onSortDirectionChanged(sortDirection: SortDirection): void {
    this.sortChanged.emit({ ...this.filter(), sort: { ...this.filter().sort, direction: sortDirection === SortDirection.ASC ? 'asc' : 'desc' } });
  }

  protected onSortFieldChanged(sortField: SortField): void {
    this.sortChanged.emit({ ...this.filter(), sort: { ...this.filter().sort, active: sortField.id } });
  }
}
