import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { Library, LibraryUsageStatisticsService } from '@celum/libraries/shared';
import { ConfirmationWithListDialogConfiguration, ConfirmationWithListDialogItem } from '@celum/shared/ui';

export class LibraryDialogConfigurationUtils {
  private static iconSize = 40;

  public static getActivateLibraryDialogConfiguration(library: Library): ConfirmationWithListDialogConfiguration {
    return {
      headerText: 'LIBRARIES.DIALOGS.ACTIVATE.HEADER',
      headerParams: { name: library.name },
      items: [
        {
          icon: IconConfiguration.xLarge('lib-lock-m', '', this.iconSize).withColor(ColorConstants.BLUE_GRAY_400),
          text: 'LIBRARIES.DIALOGS.ACTIVATE.AVAILABLE_AGAIN'
        },
        {
          icon: IconConfiguration.xLarge('lib-settings-m', '', this.iconSize).withColor(ColorConstants.BLUE_GRAY_400),
          text: 'LIBRARIES.DIALOGS.ACTIVATE.SETTINGS_RESTORED'
        }
      ],
      confirmButtonText: 'LIBRARIES.DIALOGS.ACTIVATE.CONFIRM'
    };
  }

  public static getDeactivateLibraryDialogConfiguration(library: Library, statistics?: any): ConfirmationWithListDialogConfiguration {
    const items: ConfirmationWithListDialogItem[] = [
      {
        icon: IconConfiguration.xLarge('lib-lock-m', '', this.iconSize).withColor(ColorConstants.BLUE_GRAY_400),
        text: 'LIBRARIES.DIALOGS.DEACTIVATE.NO_LONGER_ACCESSIBLE'
      },
      {
        icon: IconConfiguration.xLarge('lib-settings-m', '', this.iconSize).withColor(ColorConstants.BLUE_GRAY_400),
        text: 'LIBRARIES.DIALOGS.DEACTIVATE.SETTINGS_STAY_AVAILABLE'
      },
      {
        icon: IconConfiguration.xLarge('reopen-m', '', this.iconSize).withColor(ColorConstants.BLUE_GRAY_400),
        text: 'LIBRARIES.DIALOGS.DEACTIVATE.REACTIVATION'
      }
    ];

    if (statistics && statistics.active + statistics.published > 0) {
      items.unshift({
        icon: IconConfiguration.xLarge('experience-logo', '', this.iconSize).withColor(ColorConstants.BLUE_GRAY_400),
        text: `LIBRARIES.DIALOGS.DEACTIVATE.PORTAL_USAGE${LibraryUsageStatisticsService.getLanguageKeyEnding(statistics)}`,
        translationParams: { count: statistics.active + statistics.published, published: statistics?.published }
      });
    }

    return {
      headerText: 'LIBRARIES.DIALOGS.DEACTIVATE.HEADER',
      headerParams: { name: library.name },
      items,
      confirmButtonText: 'LIBRARIES.DIALOGS.DEACTIVATE.CONFIRM'
    };
  }
}
