<clickable-popup-dialog [enableHoverColorOnOpenedMenu]="false" [contentClass]="'celum-filter_popup'" (menuOpenedChanged)="onMenuOpenedChanged($event)">
  <div popupTrigger>
    <button celum-button class="celum-filter_trigger" data-test-slib-libraries-filter-button mat-flat-button size="small">
      <div class="celum-filter_trigger-content">
        {{ 'COMMON.FILTER' | translate }}
        <celum-icon [configuration]="icons.filter"></celum-icon>
      </div>
    </button>
  </div>
  <div popupContent (click)="$event.stopPropagation()">
    <div class="celum-filter_menu" data-test-slib-libraries-filter-menu (keydown)="onKeyDown($event)">
      @if (service.vm$ | async; as vm) {
        <!-- Library name search -->
        <div class="celum-filter_menu-section">
          <p class="celum-filter_menu-section-header celum-filter_menu-section-header-text">
            {{ 'LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.LIBRARY_NAME.TITLE' | translate }}
          </p>

          <lookup-area
            #firstSearchInput
            class="celum-filter_menu-search-input"
            data-test-slib-libraries-filter-menu-search-library-name-input
            tabindex="0"
            [debounceTime]="300"
            [formFieldSize]="'small'"
            [placeholder]="'LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.LIBRARY_NAME.PLACEHOLDER' | translate"
            [value]="vm.filter.name"
            (searchValue)="memberNameSearchChanged($event)"></lookup-area>
        </div>

        <hr />

        <!-- Status checkboxes -->
        @if (filterSections.get('LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.STATUS.TITLE'); as statusFilterSectionValues) {
          <div class="celum-filter_menu-checkbox-section celum-filter_menu-checkbox-section-top">
            <p class="celum-filter_menu-checkbox-section-header celum-filter_menu-checkbox-section-header-text">
              {{ 'LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.STATUS.TITLE' | translate }}
            </p>
            <button
              *ngFor="let item of statusFilterSectionValues"
              class="celum-filter_menu-checkbox-button"
              mat-button
              [disableRipple]="true"
              (click)="toggleFilter(item.value)">
              <mat-checkbox
                class="celum-filter_menu-checkbox celum-filter_menu-checkbox-status"
                [attr.data-test-slib-libraries-filter-menu-status-checkbox]="item.value"
                [checked]="item | wrapFn: isFilterApplied : vm.filter"
                [disableRipple]="true"
                [labelPosition]="'before'"
                (change)="toggleFilter(item.value)"
                (click)="$event.stopPropagation()">
                {{ 'LIBRARIES.STATUS.' + $any(item).value | translate }}
              </mat-checkbox>
            </button>
          </div>
        }

        <hr />

        <!-- CreatedBy toggle-->
        <div class="celum-filter_menu-checkbox-section celum-filter_menu-checkbox-section-top">
          <p class="celum-filter_menu-checkbox-section-header celum-filter_menu-checkbox-section-header-text">
            {{ 'LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.CREATED_BY.TITLE' | translate }}
          </p>
          <button class="celum-filter_menu-toggle-button" mat-button [disableRipple]="true" (click)="hasCreatedByChanged()">
            <mat-slide-toggle
              class="celum-filter_menu-toggle-button-slide-toggle"
              data-test-slib-libraries-filter-menu-toggle-created-by
              [checked]="vm.filter.createdBy"
              [disableRipple]="true"
              [labelPosition]="'before'"
              (click)="hasCreatedByChanged(); $event.stopPropagation()">
              {{ 'LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.CREATED_BY.CHIP' | translate }}
            </mat-slide-toggle>
          </button>
        </div>

        <hr />

        <!-- Sync error toggle-->
        <div class="celum-filter_menu-checkbox-section celum-filter_menu-checkbox-section-top">
          <p class="celum-filter_menu-checkbox-section-header celum-filter_menu-checkbox-section-header-text">
            {{ 'LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.SYNC_ERROR.TITLE' | translate }}
          </p>
          <button class="celum-filter_menu-toggle-button" mat-button [disableRipple]="true" (click)="hasSyncErrorChanged()">
            <mat-slide-toggle
              class="celum-filter_menu-toggle-button-slide-toggle"
              data-test-slib-libraries-filter-menu-toggle-sync-errors
              [checked]="vm.filter.hasSyncError"
              [disableRipple]="true"
              [labelPosition]="'before'"
              [matTooltip]="(showSyncErrorTruncationTooltip$ | async) ? ('LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.SYNC_ERROR.PLACEHOLDER' | translate) : null"
              (click)="hasSyncErrorChanged(); $event.stopPropagation()">
              {{ 'LIBRARIES.DASHBOARD.FILTER.SEARCH.TYPES.SYNC_ERROR.PLACEHOLDER' | translate }}
            </mat-slide-toggle>
          </button>
        </div>

        <hr />

        <button
          class="celum-filter_menu-clear-all-btn"
          data-test-slib-libraries-filter-menu-clear-all-button
          tabindex="4"
          type="button"
          [disabled]="vm.noFiltersApplied"
          (click)="clearAllFilters()">
          {{ 'SHARED.CLEAR_ALL' | translate }}
        </button>
      }
    </div>
  </div>
</clickable-popup-dialog>
