import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { pick } from 'lodash-es';
import { combineLatest, distinctUntilChanged, map } from 'rxjs';

import { AccountAccess, UserDetails, UserService } from '@celum/authentication';
import { isEqualSimpleObject, isTruthy } from '@celum/core';
import { LibraryStorageService, LibraryStorageServiceState, StorageLimitStatus } from '@celum/libraries/shared';
import { AccountUser } from '@celum/shared/domain';

import { createEmptyLibrariesFilter, LibrariesFilters } from './components/libraries-filter/libraries-filter.item';
import { LibraryFilter, LOCAL_STORAGE_LIBRARIES_FILTER_KEY } from './dashboard.component';
import { LibraryCountService, LibraryCountState } from './library-count.service';

type DashboardState = {
  filters: LibraryFilter[];
  filter: LibrariesFilters;
  currentUser?: UserDetails;
};

type DashboardViewModel = {
  totalNumberOfLibraries: number;
  totalNumberOfOwnLibraries: number;
  storageState: StorageLimitStatus;
  isOwner: boolean;
  owner: Partial<AccountUser>;
} & DashboardState;

@Injectable({ providedIn: 'root' })
export class DashboardService extends ComponentStore<DashboardState> {
  public vm$ = combineLatest(
    [this.state$, this.libraryStorageService.vm$, this.userService.userInfo$.pipe(isTruthy()), this.libraryCountService.vm$],
    (state, libraryStorageServiceViewModel, userServiceVm, libraryCount: LibraryCountState) =>
      this.createViewModel(state, libraryStorageServiceViewModel, userServiceVm.tenant, libraryCount)
  );

  constructor(
    private libraryStorageService: LibraryStorageService,
    private libraryCountService: LibraryCountService,
    public userService: UserService
  ) {
    super({
      filters: JSON.parse(localStorage.getItem(LOCAL_STORAGE_LIBRARIES_FILTER_KEY)) ?? [],
      filter: createEmptyLibrariesFilter()
    });

    this.userService.userInfo$
      .pipe(
        map(vm => vm.user),
        isTruthy(),
        distinctUntilChanged(),
        takeUntilDestroyed()
      )
      .subscribe(currentUser => this.patchState({ currentUser }));

    this.select(state => pick(state, ['filters']))
      .pipe(distinctUntilChanged(isEqualSimpleObject))
      .subscribe(data => localStorage.setItem(LOCAL_STORAGE_LIBRARIES_FILTER_KEY, JSON.stringify(data.filters)));
  }

  public init(): void {
    this.libraryCountService.loadTotalNumberOfOwnLibraries();
  }

  public get currentFilter(): LibrariesFilters {
    return this.get().filter;
  }

  public get currentUser(): UserDetails {
    return this.get().currentUser;
  }

  public filterChanged(filter: LibrariesFilters): void {
    this.patchState({ filter });
  }

  private createViewModel(
    state: DashboardState,
    libraryStorageServiceState: LibraryStorageServiceState,
    tenant: AccountAccess,
    libraryCount: LibraryCountState
  ): DashboardViewModel {
    return {
      ...state,
      totalNumberOfLibraries: state.filter.createdBy ? libraryCount.totalNumberOfOwnLibraries : libraryCount.totalNumberOfLibraries,
      totalNumberOfOwnLibraries: libraryCount.totalNumberOfOwnLibraries,
      isOwner: state.currentUser.oid === tenant?.ownerOid,
      storageState: libraryStorageServiceState.storageLimitStatus,
      owner: {
        id: tenant?.ownerOid,
        firstName: tenant?.ownerName.split(' ')[0],
        lastName: tenant?.ownerName.split(' ')[1],
        email: tenant?.ownerEmail,
        profilePictureDownloadLink: tenant?.profilePictureDownloadLink
      }
    };
  }
}
